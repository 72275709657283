const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    slug: 'Slug',
    isVirtual: 'Virtuálna rubrika',
    site: 'Stránka',
    parent: 'Parent',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril',
    absolute_url: 'Absolutná url',
    sorting: 'Zoradenie subrubrík'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Parent',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  slug: 'Slug',
  textForSlug: 'Text pre Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategórie',
  meta_title: 'Meta titulok',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta kľúčové slová',
  setting_advertCategory: 'ITM area parameter (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubrika',
  setting_special: 'Špeciálna rubrika',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubrika bez článkov (zakázať možnosť nastaviť túto rubriku pre články, používa sa pre vlastné zoznamové rubriky)',
  hiddenInMenu: 'Skryté v automaticky generovanom menu',
  isArticleToppingEnabled: 'Topovať články',
  description: 'Popis rubriky',
  heroImage: 'Hero image (dostupný formát 3:2 a 16:5)',
  miniatureImage: 'Miniatúra (dostupný formát 3:2)',
  isVirtualized: 'Virtuálna rubrika',
  virtualized: 'Virtualizovať',
  unvirtualized: 'Zrušiť virtualizáciu',
  cannotVirtualize: 'Rubriku nebolo možné virtualizovať, pretože je v kolízii s týmito rubrikami: ',
  movingForbidden: 'Presun virtuálnej rubriky {rubricId} je zakázaný.',
  sorting: 'Zoradenie subrubrík',
  product: 'Produkt',
  enableVirtualizationMsg: 'Naozaj chcete virtualizovať rubriku?',
  disableVirtualizationMsg: 'Naozaj chcete zrušiť virtualizáciu rubriky?',
  conflictData: 'Rubrika koliduje s rubrikou:',
  rubricId: 'Id rubriky',
  rubricTitle: 'Názov rubriky'
}

export default messages
