const messages = {
  list: {
  },
  source: 'Zdroj',
  type: 'Type',
  site: 'Site',
  titleOrText: 'Title or text',
  title: 'Title',
  text: 'Text',
  text_content: 'Text content',
  published_at: 'Published at',
  modified_at: 'Modified at',
  hyperlink: 'Link',
  character_count: 'Character count',
  from: 'from',
  badge: 'Badge',
  should_notify: 'Publish push notification',
  regional: 'Regional',
  national: 'National',
  date: 'Date'
}

export default messages
