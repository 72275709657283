import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.EMBEDDED_APPLICATION_PERMISSIONS

const routes = [
  {
    path: 'embedded-application/new',
    component: () => import('@/views/embeddedApplication/EmbeddedApplicationNewView'),
    name: 'embedded_application_new',
    meta: {
      description: 'Embedded application new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'embedded-application/:id/edit',
    component: () => import('../../views/embeddedApplication/EmbeddedApplicationEditView'),
    name: 'embedded_application_edit',
    meta: {
      description: 'Embedded application edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'embedded-application',
    component: () => import('../../views/embeddedApplication/EmbeddedApplicationListView'),
    name: 'embedded_application_list',
    meta: {
      description: 'Embedded application list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
