const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    published_since: 'Publikované od',
    published_until: 'Publikované do',
    status: 'Stav',
    active: 'Aktívny',
    inactive: 'Neaktívny',
    type: 'Typ',
    layout: 'Typ dizajnu',
    category: 'Kategória',
    hidden: 'Skrytý',
    created_at: 'Dátum vytvorenia',
    created_by: 'Vytvoril'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    status: 'Stav',
    layout: 'Typ dizajnu',
    created_at_from: 'Vytvorené od',
    active: 'Aktívny',
    inactive: 'Neaktívny',
    category: 'Kategória',
    search: 'Hľadať'
  },
  enabled: 'Aktívovať',
  question_number: '{number}. Otázka',
  answer_number: 'Odpoveď {number}',
  main_tab: 'Hlavný obsah',
  questions_results_tab: 'Kvízové otázky a hodnotenia kvízu',
  one_question_tab: 'Otázka a odpovede',
  id: 'Id',
  title: 'Titulok',
  title_placeholder: 'Napíšte názov kvízu',
  published_since: 'Publikované od',
  published_until: 'Publikované do',
  description: 'Popis',
  image: 'Hlavný obrázok (zobrazený aj v rubrike)',
  type: 'Typ hodnotenia',
  category: 'Kategória',
  layout: 'Typ dizajnu',
  show_image: 'Zobrazenie obrázku v detaile kvízu',
  rewriteArticle: 'Prepísať dáta v článku s dátami z kvízu',
  questions_per_page: 'Počet otázok na stránke',
  categories: 'Obsahové kategórie',
  question_info_text: 'Pre pridanie otázky je potrebne kvíz najprv uložiť.',
  questions_info_text: 'Pre pridanie otázok je potrebne kvíz najprv uložiť.',
  settings: 'Nastavenia',
  results_header: 'Hodnotenia kvízu',
  result_number: '{number}. Hodnotenie',
  result_title: 'Text hodnotenia',
  result_score: 'Minimálny počet bodov',
  result_description: 'Popis',
  result_image: 'Obrázok k hodnoteniu',
  enter_minimum_two_answers: 'Prosím zadajte aspoň 2 odpovede !',
  select_date: 'Vyberte dátum',
  delete_result: 'Zmazať hodnotenie',
  add_result: 'Pridať hodnotenie',
  question_list: 'Zoznam otázok',
  question_title: 'Názov otázky',
  question_description: 'Popis',
  question_image: 'Obrázok otázky',
  answers: 'Odpovede',
  answer: 'Odpoveď',
  answer_title: 'Odpoveď',
  answer_description: 'Popis',
  answer_points: 'Počet bodov',
  answer_image: 'Obrázok k odpovedi',
  add_question: 'Pridať otázku',
  remove_question: 'Zmazať otázku',
  add_answer: 'Pridať odpoveď',
  remove_answer: 'Zmazať odpoveď',
  create_quiz_article: 'Vytvoriť kvízový článok',
  create_quiz_article_info: 'Najprv vyplňte pole "Stránka". Potom potvrďte obrázok, ktorý bude v článku ako Hlavný obrázok. A následne budete môcť vytvoriť nový článok.',
  quiz_article_created: 'Kvízový článok bol vytvorený !',
  quiz_article_created_error: 'Chyba. Problem s vytvorením kvízového článku.',
  quiz_article_modal_header: 'Pre vytvorenie článku je potrebné kvíz aktivovať. Aktivovať teraz?',
  yes: 'Áno',
  no: 'Nie',
  save_and_create_new: 'Uložiť a vytvoriť novú otázku',
  max_possible_number_of_points: 'Maximálny možný počet bodov',
  main_content: 'Hlavný obsah',
  headline_used_in_articles: 'Použité v článku',
  quiz_article_not_found: 'Kvíz sa nenachádza v žiadnom článku.',
  quiz_article_exists: 'Pre kvíz už existuje článok !',
  quiz_type: {
    personality: 'Personalizovaný',
    correct_answer: 'Pravda/Nepravda',
    one_question: 'Jedna otázka s detailom'
  },
  deprecated_quiz_type_info: 'Po uložení nebude možné znovu nastaviť "Personalizovaný", pretože je tento typ zakázaný.',
  deprecated_quiz_layout_info: 'Po uložení nebude možné znovu nastaviť "Image answers", pretože je tento dizajn zakázaný.',
  set_published_until: 'Nastaviť vypnutie z webu',
  set_order_date_to_now: 'Nastav na teraz',
  quiz_topic: 'Téma pre generovanie kvízu',
  quiz_topic_placeholder: 'Napr. Hrady a zámky na Slovensku',
  quiz_number_of_questions: 'Počet otázok',
  quiz_generate: 'Generovať kvíz',
  quiz_generate_question: 'Generovat otázku',
  quiz_generate_label: '20-30 sekúnd',
  notify: {
    quiz_fill_topic: 'Prosím, vyplňte tému kvízu.'
  }
}

export default messages
