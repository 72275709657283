export default {
  id: null,
  entityUuid: null,
  source: '',
  title: '',
  text: '',
  hyperlink: '',
  createdAt: null,
  modifiedAt: null,
  publishedAt: null,
  createdById: null,
  modifiedById: null
}
