const messages = {
  home: 'Home',
  article_list: 'Zoznam článkov',
  article_detail: 'Detail článku',
  article_edit: 'Editácia článku',
  articleByDocumentId: 'Detail článku',
  author_list: 'List autorov',
  author_detail: 'Detail autora',
  author_edit: 'Editácia autora',
  author_new: 'Nový autor',
  breakingnews_edit: 'Editácia správy',
  breakingnews_list: 'Breaking news',
  breakingnews_new: 'Vytvoriť novú správu',
  source_list: 'List zdrojov',
  source_detail: 'Detail zdroja',
  source_edit: 'Editácia zdroja',
  source_new: 'Nový zdroj',
  otherFunction_list: 'List ďalších funkcií',
  otherFunction_detail: 'Detail ďalšie funkcie',
  otherFunction_edit: 'Editácia ďalšie funkcie',
  otherFunction_new: 'Nová ďalšia funkcia',
  contentBlock_list: 'Content block list',
  contentBlock_detail: 'Content block detail',
  contentBlock_edit: 'Content block edit',
  contentBlock_new: 'Content block new',
  pollBlock_list: 'Poll block list',
  pollBlock_detail: 'Poll block detail',
  pollBlock_edit: 'Poll block edit',
  pollBlock_new: 'Poll block new',
  topic_list: 'Zoznam tém',
  topic_detail: 'Detail témy',
  topic_edit: 'Editácia témy',
  topic_new: 'Nová téma',
  car_list: 'Zoznam vozidiel',
  car_detail: 'Detail vozidla',
  car_edit: 'Editácia vozidla',
  car_new: 'Nové vozidlo',
  restaurant_list: 'Zoznam reštaurácií',
  restaurant_detail: 'Detail reštaurácie',
  restaurant_edit: 'Editácia reštaurácie',
  restaurant_new: 'Nová reštaurácia',
  hotel_list: 'Zoznam hotelov',
  hotel_detail: 'Detail hotela',
  hotel_edit: 'Editácia hotela',
  hotel_new: 'Nový hotel',
  contentBlockItem_list: 'HP management',
  contentBlockItem_list_old: 'HP management(old)',
  dam_list: 'DAM zoznam fotiek',
  dam_pdf_list: 'DAM zoznam dokumentov',
  native_campaign_list: 'Prehľad spustených kampaní',
  native_campaign_detail: 'Náhľad kampane',
  native_campaign_edit: 'Upraviť kampaň',
  native_campaign_duplicate: 'Zduplikovať kampaň',
  native_campaign_from_article: 'Vytvoriť kampaň z článku',
  native_campaign_new: 'Vytvoriť kampaň',
  don_npa_list: 'Manažment kampaní',
  don_npa_predictions: 'Vývoj kampaní',
  don_npa_detail: 'Náhľad kampane',
  don_npa_edit: 'Upraviť kampaň',
  don_npa_duplicate: 'Zduplikovať kampaň',
  don_npa_new: 'Vytvoriť kampaň',
  don_npa_config: 'Konfigurácia DON boxu',
  dashboard: 'Realtime štatistiky',
  dashboard_old: 'Realtime štatistiky (old)',
  welcome: 'Vitajte',
  infobox_list: 'Zoznam infoboxov',
  infobox_detail: 'Detail infoboxu',
  infobox_edit: 'Editácia infoboxu',
  infobox_new: 'Nový infobox',
  joke_list: 'Zoznam vtipov',
  joke_detail: 'Detail vtipu',
  joke_edit: 'Editácia vtipu',
  joke_new: 'Nový vtip',
  quote_list: 'Zoznam myšlienok týždňa',
  quote_detail: 'Detail myšlienky týždňa',
  quote_edit: 'Editácia myšlienky týždňa',
  quote_new: 'Nová myšlienka týždňa',
  poll_list: 'Zoznam ankiet',
  poll_detail: 'Detail ankety',
  poll_edit: 'Editácia ankety',
  poll_new: 'Nová anketa',
  poll_management: 'Ankety na Homepage',
  likeDislike_list: 'Zoznam páči nepáči',
  likeDislike_detail: 'Detail páči nepáči',
  likeDislike_edit: 'Editácia páči nepáči',
  likeDislike_new: 'Nová páči nepáči',
  quiz_list: 'Zoznam kvízov',
  quiz_detail: 'Detail kvízu',
  quiz_edit: 'Editácia kvízu',
  quiz_new: 'Nový kvíz',
  quizQuestion_new: 'Nová otázka kvízu',
  quizQuestion_edit: 'Editácia otázky kvízu',
  oneQuestion_list: 'Zoznam - jedna otázka',
  oneQuestion_detail: 'Detail - jedna otázka',
  oneQuestion_edit: 'Editácia - jedna otázka',
  oneQuestion_new: 'Nový - jedna otázka',
  oneQuestionQuestion_new: 'Nová otázka jednej otázky',
  oneQuestionQuestion_edit: 'Editácia otázky jednej otázky',
  historical_dashboard: 'Štatistiky produktu',
  beUserKpi_list: 'KPI manažment',
  beUserKpi_edit: 'Uprav KPI',
  kpi_comparison: 'Porovnaj KPI',
  performance_kpi_settings: 'Nastavenie KPI',
  performance_product: 'Výkonnosť produktu',
  performance_department: 'Výkonnosť oddelenia',
  historical_performance: 'Historická výkonnosť',
  performance_coefficient: 'Nastavenie koeficientov',
  performance_weights: 'Nastavenie váh',
  edonProduct_list: 'Produkty',
  edonProduct_new: 'Nový produkt',
  edonProduct_edit: 'Uprav produkt',
  beUser_list: 'BE užívatelia',
  beUser_edit: 'Editácia BE užívateľa',
  beUser_new: 'Nový BE užívateľ',
  feUser_list: 'Fe user list',
  feUser_edit: 'Fe user edit',
  feUser_new: 'Fe user new',
  feUserArticle_list: 'FeUser Article list',
  feUserArticle_edit: 'FeUser Article edit',
  feUserArticle_new: 'FeUser Article new',
  feUserBillingAddress_list: 'FeUser Billing Address list',
  feUserBillingAddress_edit: 'FeUser Billing Address edit',
  feUserBillingAddress_new: 'FeUser Billing Address new',
  feUserDeliveryAddress_list: 'FeUser Delivery Address list',
  feUserDeliveryAddress_edit: 'FeUser Delivery Address edit',
  feUserDeliveryAddress_new: 'FeUser Delivery Address new',
  feUserPollVote_list: 'Ankety',
  hottopics_edit: 'Hot Topics - editácia panelu',
  hottopics_list: 'Hot Topics - zoznam panelov',
  hottopics_new: 'Hot Topics - nový panel',
  site_list: 'Site list',
  site_edit: 'Site edit',
  rubric_list: 'Rubric list',
  rubric_edit: 'Rubric edit',
  rubric_new: 'Rubric new',
  category_list: 'Category list',
  category_edit: 'Category edit',
  category_new: 'Category new',
  tag_list: 'Tag list',
  tag_edit: 'Tag edit',
  tag_new: 'Tag new',
  commonTags_list: 'Tagy - zoznam',
  geneeaTags_list: 'Tagy Geneea - zoznam',
  geneeaTag_edit: 'Tagy Geneea - úprava',
  geneeaTag_detail: 'Tagy Geneea - detail',
  editorialTags_list: 'Redakčné tagy - zoznam',
  editorialTag_edit: 'Redakčné tagy - úprava',
  editorialTag_detail: 'Redakčné tagy - detail',
  editorialTag_new: 'Redakčné tagy - nový',
  themeSpecialAndSeries_list: 'Témy, špeciály a seriály - zoznam',
  themeSpecialAndSeries_edit: 'Témy, špeciály a seriály - editácia',
  special_detail: 'Špeciály - detail',
  theme_detail: 'Témy - detail',
  series_detail: 'Seriály - detail',
  themeSpecialAndSeries_detail: 'Témy, špeciály a seriály - detail',
  themeSpecialAndSeries_new: 'Témy, špeciály a seriály - nový',
  department_list: 'Oddelenia',
  department_edit: 'Uprav oddelenia',
  department_new: 'Nové oddelenie',
  app_performance: 'App Performance',
  scale_list: 'Váhy',
  scale_edit: 'Uprav váhy',
  safetyTopic_list: 'SafetyTopic list',
  safetyTopic_edit: 'SafetyTopic edit',
  safetyTopic_new: 'SafetyTopic new',
  redirect_list: 'Zoznam presmerovaní',
  redirect_edit: 'Editácia presmerovania',
  redirect_new: 'Nové presmerovanie',
  redirect_detail: 'Detail presmerovania',
  feature_list: 'Feature list',
  feature_edit: 'Feature edit',
  articleHistory_list: 'Article history list',
  sport24_list: 'Športové tabuľky',
  sport24_detail: 'Športové tabuľky - detail',
  help_video_list: 'Zoznam videí',
  userLoginAttempt_list: 'User Login Attempt list',
  tasrNews_list: 'Zoznam TASR správ',
  tasrNews_detail: 'Detail TASR správy',
  tasrNewsCategory_list: 'List TASR news category',
  tasrNewsCategory_edit: 'Edit TASR news category',
  tasrNewsCategory_new: 'New TASR news category',
  topicOffer_list: 'Zoznam ponuky obsahu',
  topicOffer_detail: 'Detail ponuky obsahu',
  printTitle_list: 'List Print Titles',
  printTitle_detail: 'Detail Print Title',
  printTitle_edit: 'Edit Print Title',
  printTitle_new: 'New Print Title',
  printPublication_preview: 'Aktuálne printové vydania na webe',
  printPublication_list: 'Zoznam printových vydaní',
  printPublication_detail: 'Detail printového vydania',
  printPublication_edit: 'Editácia printového vydania',
  printPublication_new: 'Nové printové vydanie',
  video_list: 'Zoznam videí',
  video_list_old: 'Zoznam videí(old)',
  video_detail: 'Detail videa',
  video_detail_old: 'Detail videa(old)',
  video_edit: 'Editácia videa',
  video_edit_old: 'Editácia videa(old)',
  video_new: 'Nové video',
  video_new_old: 'Nové video(old)',
  videoAnnotation_list: 'Zoznam anotácií',
  videoAnnotation_list_old: 'Zoznam anotácií(old)',
  videoAnnotation_detail: 'Detail anotácie',
  videoAnnotation_detail_old: 'Detail anotácie(old)',
  videoAnnotation_edit: 'Editácia anotácie',
  videoAnnotation_edit_old: 'Editácia anotácie(old)',
  videoAnnotation_new: 'Nová anotácia',
  videoAnnotation_new_old: 'Nová anotácia(old)',
  videoShow_list: 'Video show list',
  videoShow_detail: 'Video show detail',
  videoShow_edit: 'Video show edit',
  videoShow_new: 'Video show new',
  videoCategory_list: 'Video category list',
  videoCategory_detail: 'Video category detail',
  videoCategory_edit: 'Video category edit',
  videoCategory_new: 'Video category new',
  videoManagement: 'Video management',
  videoDashboard: 'Štatistiky videí',
  videoKpiReports: 'Report uploadnutých videí',
  printArticle_list: 'Printové články',
  printArticle_edit: 'Uprav printový článok',
  printArticle_new: 'Nový printový článok',
  eshopSubscriptionProduct_list: 'Produkty',
  eshopSubscriptionProduct_new: 'Nový produkt',
  eshopSubscriptionProduct_edit: 'Uprav produkt',
  eshopSubscriptionOrder_list: 'Objednávky',
  eshopSubscriptionOrder_detail: 'Objednávka',
  eshopSubscriptionPayment_list: 'Platby',
  eshopSubscriptionPayment_detail: 'Platba',
  eshopSubscription_list: 'Predplatné',
  eshopSubscription_new: 'Nové predplatné',
  eshopSubscription_edit: 'Uprav predplatné',
  eshopSubscriber_list: 'Predplatitelia',
  eshopHistory_list: 'História',
  eshopFeature_list: 'Eshop feature list',
  eshopFeature_edit: 'Eshop feature edit',
  eshopDashboard: 'Dashboard',
  eshopTopProductSetting_edit: 'Uprav TOP Produkty',
  videoDailyReport: 'Report redakčných videí',
  videoPublishedReport: 'Report publikovaných videí',
  notification_detail: 'Detail serveru',
  notification_edit: 'Editácia serveru',
  notification_list: 'Notifikácie - servery',
  notification_new: 'Nový server',
  permissionGroup_list: 'Skupiny oprávnení',
  permissionGroup_new: 'Nová skupina oprávnení',
  permissionGroup_detail: 'Detail skupiny oprávnení',
  permissionGroup_edit: 'Editácia skupiny oprávnení',
  recipe_detail: 'Detail receptu',
  recipe_edit: 'Editácia receptu',
  recipe_list: 'Recepty',
  recipe_new: 'Nový recept',
  recipe_category_edit: 'Editácia kategórie receptu',
  recipe_category_list: 'Kategórie receptu',
  recipe_category_new: 'Nová kategória receptu',
  hp_topic_offer_list: 'Homepage ponuka článkov',
  hp_topic_offer_list_old: 'Homepage ponuka článkov(old)',
  woodWing_detail: 'Detail WoodWing článku',
  woodWing_list: 'Homepage WoodWing článkov',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historické NMH KPI',
  kpi_nmh_settings: 'NMH KPI nastavenia',
  gallery_detail: 'Detail galérie',
  gallery_edit: 'Editácia galérie',
  gallery_list: 'Galérie',
  gallery_new: 'Nová galéria',
  userLogout: 'Odhlásenie užívateľa',
  right_now_detail: 'Detail práve teraz',
  right_now_new: 'Nová práve teraz',
  right_now_edit: 'Editácia práve teraz',
  right_now_list: 'Práve teraz',
  news_of_the_day_detail: 'Detail správ dňa',
  news_of_the_day_new: 'Nová správa dňa',
  news_of_the_day_edit: 'Editácia správ dňa',
  news_of_the_day_list: 'Správy dňa',
  embedded_application_new: 'Nová VLM aplikácia',
  embedded_application_edit: 'Editácia VLM aplikácie',
  embedded_application_list: 'VLM aplikácie'
}

export default messages
