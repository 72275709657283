export const apiResponsePlugin = store => {
  store.handleApiError = (store, error) => {
    if (error?.response?.status === 500) {
      store.commit('setError', error.response.status)
    } else {
      store.commit('setError', error?.response?.data?.error)
    }
  }

  store.handleApiResponse = (store, response, status, storeDetail = false) => {
    if (response.status === status) {
      store.commit('setError', null)
      storeDetail && store.commit('storeDetail', response.data)
    } else {
      store.commit('setError', 'Error')
    }
  }
}
