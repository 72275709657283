const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.displayName) {
    filterQuery += '&filter_contains[displayName]=' + filter.displayName
  }
  if (filter.identifier) {
    filterQuery += '&filter_contains[identifier]=' + filter.identifier
  }
  if ([0, 1].includes(filter.isActive)) {
    filterQuery += '&filter_eq[isActive]=' + !!filter.isActive
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
