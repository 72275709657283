const messages = {
  createModule: 'Vytvoriť modul',
  viewModule: 'Náhľad modulu',
  editModule: 'Editovať modul',
  moduleId: 'ID modulu',
  createdBy: 'Vytvoril (dátum vytvorenia)',
  modifiedBy: 'Posledná zmena (dátum zmeny)',
  infobox: 'Infobox',
  joke: 'Vtip',
  quote: 'Myšlienka týždňa',
  poll: 'Anketa',
  likeDislike: 'Páči / Nepáči anketa',
  quiz: 'Kvíz',
  quizOneQuestion: 'Jedna otázka',
  sport24: 'Športová tabulka',
  topic: 'Téma',
  recipe: 'Recept',
  video: 'Video',
  gallery: 'Galéria',
  redirect: 'Presmerovanie',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  rightNow: 'Práve teraz',
  newsOfTheDay: 'Správy dňa',
  embeddedApplication: 'VLM aplikácie'
}

export default messages
