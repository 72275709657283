const messages = {
  list: {
    id: 'Id',
    title: 'Title',
    slug: 'Slug',
    isVirtual: 'Virtual rubric',
    site: 'Site',
    parent: 'Parent',
    created_at: 'Created at',
    modified_at: 'Modified at',
    created_by: 'Created by',
    absolute_url: 'Absolute url',
    sorting: 'Subrubric Sorting'
  },
  filter: {
    title: 'Title',
    id: 'Id',
    slug: 'Slug',
    site: 'Site',
    parent: 'Parent',
    search: 'Search'
  },
  id: 'Id',
  title: 'Title',
  slug: 'Slug',
  textForSlug: 'Text for Slug',
  site: 'Site',
  parent: 'Parent',
  iptcCategory: 'IPTC categories',
  meta_title: 'Meta title',
  meta_description: 'Meta description',
  meta_keywords: 'Meta keywords',
  setting_advertCategory: 'ITM area parameter (advertCategory)',
  setting_chartbeatSection: 'Chartbeat section',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubric',
  setting_special: 'Special rubric',
  setting_advertSection: 'Unit section for Google ads (advertSection)',
  sportTable_seasonId: 'Sport table Season Id',
  sportTable_activeTab: 'Sport table Active Tab (first = 1)',
  rubric_without_articles: 'Rubric without articles (disable possibility to set this rubric to articles, used for custom listing rubrics)',
  hiddenInMenu: 'Hidden in autogenerated menu',
  isArticleToppingEnabled: 'Top articles',
  description: 'Rubric description',
  heroImage: 'Hero image (available formats 3:2 and 16:5)',
  miniatureImage: 'Miniature (available format 3:2)',
  isVirtualized: 'Virtual rubric',
  virtualized: 'Virtualize',
  unvirtualized: 'Cancel Virtualization',
  cannotVirtualize: 'The rubric could not be virtualized because it collides with the following rubrics: ',
  movingForbidden: 'Moving of virtual rubric {rubricId} is forbidden.',
  sorting: 'Subrubric Sorting',
  product: 'Product',
  enableVirtualizationMsg: 'Do you really want to virtualize the rubric?',
  disableVirtualizationMsg: 'Do you really want to cancel the virtualization of the rubric?',
  conflictData: 'Rubric conflicts with rubric:',
  rubricId: 'Rubric ID',
  rubricTitle: 'Rubric Title'
}

export default messages
