const messages = {
  title: 'Application title',
  identifier: 'VLM Application ID',
  active: 'Active',
  inactive: 'Inactive',
  actions: 'Actions',
  sites: 'Sites',
  webhookUrl: 'Webhook URL',
  mainSettings: 'Main settings',
  fieldSettings: 'Field settings',
  field: {
    title: 'Field title',
    titleCP: 'Field title in CP',
    type: 'Data type',
    required: 'Required field',
    colorboxUrl: 'Colorbox URL',
    add: 'Add field'
  },
  field_already_exists: 'Field with this identifier already exists',
  application_must_have_at_least_one_field: 'Application must have at least one field',
  modal: {
    article_info: 'Article list where is used the VLM application'
  }
}

export default messages
