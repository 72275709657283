const messages = {
  title: 'Názov aplikácie',
  identifier: 'VLM ID aplikácie',
  active: 'Aktívny',
  inactive: 'Neaktívny',
  actions: 'Akcie',
  sites: 'Stránky',
  webhookUrl: 'Webhook URL',
  mainSettings: 'Hlavné nastavenia',
  fieldSettings: 'Nastavenia polí',
  field: {
    title: 'Názov poľa',
    titleCP: 'Názov poľa v CP',
    type: 'Dátový typ',
    required: 'Povinné pole',
    colorboxUrl: 'Colorbox URL',
    add: 'Pridať pole'
  },
  field_already_exists: 'Pole s týmto názvom už existuje',
  application_must_have_at_least_one_field: 'Aplikácia musí mať aspoň jedno pole',
  modal: {
    article_info: 'Zoznam článkov, kde je použitá VLM aplikácia'
  }
}

export default messages
