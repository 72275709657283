const messages = {
  list: {
  },
  source: 'Zdroj',
  type: 'Typ',
  site: 'Deník',
  titleOrText: 'Titulok alebo text',
  title: 'Titulok',
  text: 'Text',
  text_content: 'Text správy',
  published_at: 'Publikované',
  modified_at: 'Zmenené',
  hyperlink: 'Link',
  character_count: 'Počet znakov',
  from: 'z',
  badge: 'Odznak',
  should_notify: 'Publikovať push notifikáciu',
  regional: 'Regionálne',
  national: 'Celoštátne',
  date: 'Dátum'
}

export default messages
