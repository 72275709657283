const messages = {
  title: 'Název aplikace',
  identifier: 'VLM ID aplikace',
  active: 'Aktivní',
  inactive: 'Neaktivní',
  actions: 'Akce',
  sites: 'Stránky',
  webhookUrl: 'Webhook URL',
  mainSettings: 'Hlavní nastavení',
  fieldSettings: 'Nastavení polí',
  field: {
    title: 'Název pole',
    titleCP: 'Název pole v CP',
    type: 'Datový typ',
    required: 'Povinné pole',
    colorboxUrl: 'Colorbox URL',
    add: 'Přidat pole'
  },
  field_already_exists: 'Pole s tímto názvem již existuje',
  application_must_have_at_least_one_field: 'Aplikace musí mít alespoň jedno pole',
  modal: {
    article_info: 'Seznam článků, kde je použita VLM aplikace'
  }
}

export default messages
