import CoreApi from '@/api/core'
import config from '@/config'
import _ from 'lodash'
import EmbeddedApplicationModel from '@/model/EmbeddedApplicationModel'
import EmbeddedApplicationFilter from '@/model/EmbeddedApplicationFilter'
import EmbeddedApplicationFilterService from '@/services/embeddedApplication/EmbeddedApplicationFilterService'

const API_NAME = '/EmbeddedApplication'

const state = {
  error: null,
  detail: EmbeddedApplicationModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(EmbeddedApplicationFilter)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(EmbeddedApplicationFilter)
  }
}

const actions = {
  async fetch (store, data) {
    let response = null

    store.commit('TOGGLE_LOADING', null, { root: true })
    const limit = config.defaults.list.limit
    const offset = (store.state.page * limit) - limit
    const params = { ...store.state.filter, ...data }
    const url = API_NAME +
      '?limit=' + limit +
      '&offset=' + offset +
      EmbeddedApplicationFilterService.buildFilterQuery(params)

    try {
      const result = await CoreApi().get(url)
      response = result.data
      store.commit('storeList', response)
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('TOGGLE_LOADING', null, { root: true })
    }
    return response
  },
  async fetchOne (store, id) {
    const response = await CoreApi().get(API_NAME + '/' + id)
    store.commit('storeDetail', response.data)
    return response.data
  },
  async create (store, record) {
    try {
      const response = await CoreApi().post(API_NAME, JSON.stringify(record))
      if (response.status === 201) {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
        return response.data
      }
      store.commit('setError', 'Error')
    } catch (error) {
      if (error.response?.status === 500) {
        store.commit('setError', error.response?.status)
      } else {
        store.commit('setError', error.response?.data?.error)
      }
    }
  },
  async update (store, record) {
    try {
      const response = await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      if (response.status === 200) {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
        return response.data
      }
      store.commit('setError', 'Error')
    } catch (error) {
      if (error.response?.status === 500) {
        store.commit('setError', error.response?.status)
      } else {
        store.commit('setError', error.response?.data?.error)
      }
    }
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
