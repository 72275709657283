const messages = {
  list: {
  },
  source: 'Zdroj',
  type: 'Typ',
  site: 'Deník',
  titleOrText: 'Titulek nebo text',
  title: 'Titulek',
  text: 'Text',
  text_content: 'Text zprávy',
  published_at: 'Publikováno',
  modified_at: 'Změněno',
  hyperlink: 'Link',
  character_count: 'Počet znaku',
  from: 'z',
  badge: 'Odznak',
  should_notify: 'Publikovat push notifikaci',
  regional: 'Regionální',
  national: 'Celostátní',
  date: 'Datum'
}

export default messages
