import CoreApi from '@/api/core'
import config from '@/config'
import _ from 'lodash'
import RightNowFilter from '@/model/RightNowFilter'
import RightNowFilterService from '@/services/rightNow/RightNowFilterService'
import RightNowModel from '@/model/RightNowModel'

const API_NAME = '/RightNowItem'

const state = {
  error: null,
  detail: RightNowModel,
  list: [],
  totalCount: 0,
  page: 1,
  filter: _.cloneDeep(RightNowFilter)
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = _.cloneDeep(RightNowFilter)
  }
}

const actions = {
  async fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * config.defaults.list.limit) - config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + config.defaults.list.limit +
      '&offset=' + offset + RightNowFilterService.buildFilterQuery(store.state.filter)

    try {
      const response = await CoreApi().get(url)
      store.commit('storeList', response.data)
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('TOGGLE_LOADING', null, { root: true })
    }
  },
  async fetchOne (store, id) {
    try {
      const response = await CoreApi().get(API_NAME + '/' + id)
      store.commit('storeDetail', response.data)
    } catch (error) {
      console.error(error)
    }
  },
  async create (store, record) {
    record = { ...record, siteIds: record.sites.map(site => site.id) }
    try {
      const response = await CoreApi().post(API_NAME, JSON.stringify(record))
      this.handleApiResponse(store, response, 201, true)
      return response
    } catch (error) {
      this.handleApiError(store, error)
    }
  },
  async update (store, record) {
    record = { ...record, siteIds: record.sites.map(site => site.id) }
    try {
      const response = await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      this.handleApiResponse(store, response, 200, true)
      return response
    } catch (error) {
      this.handleApiError(store, error)
    }
  },
  async deleteRecord (store, record) {
    try {
      const response = await CoreApi().delete(API_NAME + '/' + record.id)
      this.handleApiResponse(store, response, 204)
      return response
    } catch (error) {
      this.handleApiError(store, error)
    }
  },
  async publish (store, record) {
    try {
      const response = await CoreApi().put(API_NAME + '/publish/' + record.id)
      this.handleApiResponse(store, response, 200)
      return response
    } catch (error) {
      this.handleApiError(store, error)
    }
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
