import moment from 'moment'

const formatDateRange = (field, date) => {
  const formattedDate = moment(date).format('YYYY-MM-DD')
  return `&filter_gte[${field}]=${formattedDate}T00:00:00.000Z&filter_lte[${field}]=${formattedDate}T23:59:59.999Z`
}

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.text) {
    filterQuery += '&filter_contains[text]=' + filter.text
  }
  if (filter.modifiedAt) {
    filterQuery += formatDateRange('modifiedAt', filter.modifiedAt)
  }
  if (filter.publishedAt) {
    filterQuery += formatDateRange('publishedAt', filter.publishedAt)
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
