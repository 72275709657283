import PermissionService from '@/services/PermissionService'

const requiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS

const routes = [
  {
    path: 'right-now/new',
    component: () => import('../../views/rightNow/RightNowNewView'),
    name: 'right_now_new',
    meta: {
      description: 'Right now new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'right-now/:id/edit',
    component: () => import('../../views/rightNow/RightNowEditView'),
    name: 'right_now_edit',
    meta: {
      description: 'Right now edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'right-now/:id',
    component: () => import('../../views/rightNow/RightNowView'),
    name: 'right_now_detail',
    meta: {
      description: 'Right now detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'right-now',
    component: () => import('../../views/rightNow/RightNowListView'),
    name: 'right_now_list',
    meta: {
      description: 'Right now list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  },
  {
    path: 'news-of-the-day/new',
    component: () => import('../../views/newsOfTheDay/NewsOfTheDayNewView'),
    name: 'news_of_the_day_new',
    meta: {
      description: 'News of the day new',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.newRoute
    }
  },
  {
    path: 'news-of-the-day/:id/edit',
    component: () => import('../../views/newsOfTheDay/NewsOfTheDayEditView'),
    name: 'news_of_the_day_edit',
    meta: {
      description: 'News of the day edit',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.editRoute
    }
  },
  {
    path: 'news-of-the-day/:id',
    component: () => import('../../views/newsOfTheDay/NewsOfTheDayView'),
    name: 'news_of_the_day_detail',
    meta: {
      description: 'News of the day detail',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.detailRoute
    }
  },
  {
    path: 'news-of-the-day',
    component: () => import('../../views/newsOfTheDay/NewsOfTheDayListView'),
    name: 'news_of_the_day_list',
    meta: {
      description: 'News of the day list',
      requiresAuth: true,
      requiredPermissionModules: requiredPermissions.listRoute
    }
  }
]

export default routes
